// prefer default export if available

const preferDefault = m => m && m.default || m
exports.components = {
  "component---src-theme-organisms-banner-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/a4b533fa-c700-5c12-b668-29028a66abe3.js" /* webpackChunkName: "component---src-theme-organisms-banner-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-banner-brand-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/09e18d69-4e06-5232-9e03-7b17a18ca587.js" /* webpackChunkName: "component---src-theme-organisms-banner-brand-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-banner-text-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/162940f8-8f93-517a-b538-1fce3f17dde9.js" /* webpackChunkName: "component---src-theme-organisms-banner-text-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-button-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/0841bf4e-005b-53af-9e93-ffad6e1bb255.js" /* webpackChunkName: "component---src-theme-organisms-button-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-category-banner-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/8a12b2f7-e94e-5a33-b352-f86368b98312.js" /* webpackChunkName: "component---src-theme-organisms-category-banner-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-category-banner-border-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/e17d2c7e-bb15-5991-9fa5-2ffb094138bc.js" /* webpackChunkName: "component---src-theme-organisms-category-banner-border-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-category-banner-border-horizontal-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/e28ce142-2241-532b-a516-93d0d9769c15.js" /* webpackChunkName: "component---src-theme-organisms-category-banner-border-horizontal-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-category-banner-rounded-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/9a7deade-8651-513a-b24f-639bca5b6439.js" /* webpackChunkName: "component---src-theme-organisms-category-banner-rounded-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-category-teaser-list-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/9b90b724-b307-5889-beea-a132fc941170.js" /* webpackChunkName: "component---src-theme-organisms-category-teaser-list-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-currated-category-widget-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/d6df92be-dd6a-5149-acab-f6387c95dc7f.js" /* webpackChunkName: "component---src-theme-organisms-currated-category-widget-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-currated-search-widget-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/788ceca1-383f-5c2a-97b9-940705885231.js" /* webpackChunkName: "component---src-theme-organisms-currated-search-widget-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-deal-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/91b308f2-c3e3-5f87-8363-19507fca7d66.js" /* webpackChunkName: "component---src-theme-organisms-deal-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-divider-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/c2c00216-9cf6-56d9-9035-c29f251aa509.js" /* webpackChunkName: "component---src-theme-organisms-divider-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-embedly-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/993f8eca-50fe-5337-91a0-c3c1fa3c6b6d.js" /* webpackChunkName: "component---src-theme-organisms-embedly-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-embedly-gallery-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/7b411b9e-9202-5102-aeda-24fa0764fcb0.js" /* webpackChunkName: "component---src-theme-organisms-embedly-gallery-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-fashion-product-list-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/6fa55258-33eb-5a1f-b9e3-88ebef07a148.js" /* webpackChunkName: "component---src-theme-organisms-fashion-product-list-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-figurtypen-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/1b90ae7c-4cd3-566c-93d9-9c569f94565d.js" /* webpackChunkName: "component---src-theme-organisms-figurtypen-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-fullwidth-hero-banner-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/2b4c257d-21fc-55dc-8cff-9b87d269561d.js" /* webpackChunkName: "component---src-theme-organisms-fullwidth-hero-banner-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-homepage-brand-gallery-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/abaaf3b3-0482-587a-97b8-14da15a86918.js" /* webpackChunkName: "component---src-theme-organisms-homepage-brand-gallery-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-homepage-recommendations-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/52b971b5-2f56-5331-a551-2dae59edd679.js" /* webpackChunkName: "component---src-theme-organisms-homepage-recommendations-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-i-frame-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/13e7c77d-cd3e-5414-9297-78410942ec76.js" /* webpackChunkName: "component---src-theme-organisms-i-frame-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-image-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/ef0c00cc-9173-5d98-b45d-f392b53eaace.js" /* webpackChunkName: "component---src-theme-organisms-image-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-image-gallery-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/4a94a516-19aa-5420-9af6-23911f070617.js" /* webpackChunkName: "component---src-theme-organisms-image-gallery-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-image-with-links-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/a137c191-2f8f-51ce-bf7f-fc549859ddbe.js" /* webpackChunkName: "component---src-theme-organisms-image-with-links-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-image-with-links-banner-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/3351d584-7c14-55fe-acad-4470b5af4926.js" /* webpackChunkName: "component---src-theme-organisms-image-with-links-banner-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-magazine-article-teaser-by-id-widget-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/79e35d3e-a582-50c9-8684-b588223012d3.js" /* webpackChunkName: "component---src-theme-organisms-magazine-article-teaser-by-id-widget-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-newsletter-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/904b8a67-a2e5-5597-9ed7-6f59493686d7.js" /* webpackChunkName: "component---src-theme-organisms-newsletter-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-newsletter-success-popup-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/65e59c1a-acf7-5e84-a9fe-7d767cd5b4c1.js" /* webpackChunkName: "component---src-theme-organisms-newsletter-success-popup-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-placeholder-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/f33a6c20-a16b-518c-ace9-7113f13e2cbb.js" /* webpackChunkName: "component---src-theme-organisms-placeholder-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-product-list-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/c07bc260-8e5c-5f39-a542-2701d17d78c0.js" /* webpackChunkName: "component---src-theme-organisms-product-list-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-simple-question-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/193afd0b-23e1-58c5-a10a-690f2ce3e490.js" /* webpackChunkName: "component---src-theme-organisms-simple-question-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-sizebar-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/905ee23d-e433-5d15-bfb2-a59a6be7770b.js" /* webpackChunkName: "component---src-theme-organisms-sizebar-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-squarelovin-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/fe6f13de-a2f1-5b6d-9e1a-614dfbbca6f5.js" /* webpackChunkName: "component---src-theme-organisms-squarelovin-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-squarelovin-full-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/a2ac6719-779e-5c70-b78c-b99d2b7604a1.js" /* webpackChunkName: "component---src-theme-organisms-squarelovin-full-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-squarelovin-tagged-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/0afce086-1508-5abf-9f5e-75252dc44ec4.js" /* webpackChunkName: "component---src-theme-organisms-squarelovin-tagged-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-storefinder-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/954fe254-e345-5519-8afc-cb6009f0d100.js" /* webpackChunkName: "component---src-theme-organisms-storefinder-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-suggestion-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/0a7ca6d4-016d-58c1-91e0-c659e66257c1.js" /* webpackChunkName: "component---src-theme-organisms-suggestion-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-text-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/1a986ccb-e0af-5369-8722-68aa564f8199.js" /* webpackChunkName: "component---src-theme-organisms-text-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-text-with-embedly-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/78ddead3-92ba-520f-b5ee-8936bb42de19.js" /* webpackChunkName: "component---src-theme-organisms-text-with-embedly-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-text-with-image-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/f8edb60f-7ed2-5290-ae28-74b047ef5e6a.js" /* webpackChunkName: "component---src-theme-organisms-text-with-image-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-text-with-image-gallery-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/067239ed-a179-5d21-84cd-2d76d96586e7.js" /* webpackChunkName: "component---src-theme-organisms-text-with-image-gallery-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-video-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/fd869400-b24c-568d-8f60-43e474bfbadb.js" /* webpackChunkName: "component---src-theme-organisms-video-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-wishlist-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/48cdeefe-7d5c-5677-90e0-8a8d16415c67.js" /* webpackChunkName: "component---src-theme-organisms-wishlist-index-ts" */).then(preferDefault),
  "component---src-theme-organisms-molecule-test-index-ts": () => import("/opt/build/repo/.cache/gatsby-plugin-graphql-component/components/d1584c7f-4fd7-56bc-a9e9-315036389bec.js" /* webpackChunkName: "component---src-theme-organisms-molecule-test-index-ts" */).then(preferDefault)
}

